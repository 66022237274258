<template>
  <CommonLayout v-if="bonusTasks && bonusTasks.length">
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__row">
            <div class="cabinet__col">
              <h1 class="title">Бонусная задача</h1>
            </div>
          </div>
          <div class="cabinet__row mb-50">
            <div class="cabinet__col">
              <div class="subtitle weight-400 mt-30">
                У вас {{ bonusTasks.length }} {{ bonusTasksAmount }}:
              </div>
              <NavSlider class="mt-10">
                <div
                  v-for="(task, i) of bonusTasks"
                  :key="task.id"
                  class="nav-slider__item"
                >
                  <a
                    @click.prevent="setActiveBonusTask(i)"
                    :class="{ active: activeBonusTask === i }"
                  >
                    <span>{{ task.title }}</span>
                  </a>
                </div>
              </NavSlider>
            </div>
          </div>
          <div class="cabinet__row">
            <div class="cabinet__col">
              <div class="bonus-task-head">
                <div class="bonus-task-head__row">
                  <div class="bonus-task-head__group">
                    <div class="bonus-task-head__group-name">Задание</div>
                    <div class="bonus-task-head__group-title">
                      {{ getActiveBonusTask.title }}
                    </div>
                    <div class="bonus-task-head__group-desc">
                      {{ getActiveBonusTask.description }}
                    </div>
                  </div>
                </div>
                <div class="bonus-task-head__row">
                  <div class="bonus-task-head__col">
                    <div class="bonus-task-head__title">Текущий месяц</div>
                    <div class="bonus-task-head__subtitle">
                      {{ getCurrentMonth }}
                    </div>
                  </div>
                  <div class="bonus-task-head__col">
                    <div class="bonus-task-head__title">
                      Статус задачи за месяц
                      <Tooltip
                        class="bonus-task-head__tooltip"
                        :msg="`<table>
                                <tr><td>Назначена</td><td>подготовьтесь к испытанию</td></tr>
                                <tr><td>В работе</td><td>время постараться!</td></tr>
                                <tr><td>На модерации</td><td>подождите немного</td></tr>
                                <tr><td>Выполнена</td><td>поздравляем, награды ваши</td></tr>
                                <tr><td>НЕ ВЫПОЛНЕНА</td><td>ничего, получится в другой раз</td></tr>
                              </table>`"
                      />
                    </div>
                    <div class="bonus-task-head__subtitle">
                      {{ getCurrentState | formatTeamQuest }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cabinet__col">
              <div class="bonus-task-legend">
                <div class="bonus-task-legend__inner">
                  <div class="bonus-task-legend__head">
                    <div class="bonus-task-legend__title">
                      Награда команде при выполнении в&nbsp;этом месяце:
                    </div>
                  </div>
                  <div class="bonus-task-legend__body">
                    <Coins :reward="getActiveBonusTask.teamReward" :size="24" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <BonusTaskHistory :history="history.mounthlyTasks" />
        </div>
      </div>
    </div>
  </CommonLayout>
  <CabinetContentLayout
    v-else
    :has-button-back="true"
    :is-loading="isLoading"
    title="Бонусная задача"
  >
    <p>
      В разделе «Бонусная задача» вы будете получать командную задачу,
      <br />которую нужно выполнять каждый месяц. При выполнении в каждом <br />
      месяце, команда также получает награду ежемесячно.
    </p>
    <p>О старте Бонусной задачи вам сообщит Совет старейшин.</p>
  </CabinetContentLayout>
</template>

<script>
import { GetBonusTask, GetBonusTaskHistory } from "@/api/Player";
import BonusTaskHistory from "@/components/BonusTaskHistory.vue";
import Coins from "@/components/Coins.vue";
import NavSlider from "@/components/NavSlider.vue";
import Tooltip from "@/components/Tooltip.vue";
import CabinetContentLayout from "@/layout/CabinetContentLayout.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import { declOfNum } from "@/utils";

export default {
  components: {
    CommonLayout,
    CabinetContentLayout,
    Tooltip,
    NavSlider,
    BonusTaskHistory,
    Coins,
  },
  data() {
    return {
      isLoading: false,
      activeBonusTask: 0,
      bonusTasks: [],
      history: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    /**
     * Возвращает текущее выбранное задание
     * @returns {object}
     */
    getActiveBonusTask() {
      return this.bonusTasks[this.activeBonusTask];
    },
    /**
     * Возвращает последнюю дату из истории или текущую дату
     * @returns {string}
     */
    getCurrentMonth() {
      if (!this.getActiveBonusTask.mounthlyTasks?.length)
        return new Date()
          .toLocaleString("ru", {
            month: "long",
            year: "numeric",
          })
          .slice(0, -3);

      return this.getActiveBonusTask.mounthlyTasks[0].month;
    },
    /**
     * Возвращает последний статус из истории или текущий статус
     * Добаляем +1 из-за смещения
     * @returns {number}
     */
    getCurrentState() {
      if (!this.getActiveBonusTask.mounthlyTasks?.length) return 1;

      return this.getActiveBonusTask.mounthlyTasks[0].status + 1;
    },
    bonusTasksAmount() {
      return declOfNum(this.bonusTasks.length, [
        "бонусная задача",
        "бонусные задачи",
        "бонусных задач",
      ]);
    },
  },
  methods: {
    setActiveBonusTask(payload) {
      this.activeBonusTask = payload;
      this.getTaskHistory();
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const { data: bonusTasks } = await GetBonusTask();
        const { data: history } = await GetBonusTaskHistory({
          taskId: bonusTasks[0].id,
        });
        this.bonusTasks = bonusTasks;
        this.history = history[0];
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getTaskHistory() {
      try {
        this.isLoading = true;
        const { data: history } = await GetBonusTaskHistory({
          taskId: this.getActiveBonusTask.id,
        });

        this.history = history[0];
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bonus-task-head {
  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__col {
    margin-right: 50px;
    min-width: 250px;
  }
  &__tooltip {
    margin-bottom: -8px;
  }
  &__group {
    margin-bottom: 30px;
    padding-right: 30px;
    &-name {
      font-size: 18px;
      color: #ade5d9;
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-desc {
      font-family: $fontRafale;
      font-size: 18px;
      margin-top: 20px;
    }
  }
  &__group {
    margin-bottom: 30px;
    padding-right: 30px;
    &-name {
      font-size: 18px;
      color: #ade5d9;
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      max-height: 88px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__title {
    font-size: 18px;
    color: #ade5d9;
    display: flex;
    align-items: center;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #50ffeb;
    text-transform: uppercase;
  }
  &__text {
    font-size: 18px;
    color: #ade5d9;
    margin-bottom: 2px;
  }
}

.bonus-task-legend {
  background-image: url(~@/assets/img/pics/legend-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  height: 226px;
  &__inner {
    padding: 35px 45px;
  }
  &__head {
    position: relative;
  }
  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #50ffeb;
  }
  &__body {
    margin-top: 20px;
    .coins {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>
