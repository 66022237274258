<template>
  <div class="history-bt">
    <h2 class="history-bt__title">История выполнения задачи</h2>
    <div v-if="!history || !history.length" class="history-bt__empty">
      Здесь будут отображаться результаты за каждый месяц.<br />
      Информация обновляется 1 числа каждого месяца.<br />
      Награда начисляется за каждый месяц выполнения задачи.
    </div>
    <table v-else class="history-bt__table">
      <thead class="history-bt__t-head">
        <tr>
          <th></th>
          <th>Месяц</th>
          <th>Статус</th>
          <th>Награда</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody class="history-bt__t-body">
        <tr v-for="(h, i) of history" :key="i">
          <td></td>
          <td>{{ h.month }}</td>
          <td>
            <TaskStatus :status="h.status + 1" class="before" />
          </td>
          <td>
            <Coins :topReward="h.reward" :size="20" />
          </td>
          <td>
            <button
              type="button"
              :class="{ active: activeTogglerIdx === i }"
              @click="toggleView(i)"
            >
              {{ activeTogglerIdx === i ? "Свернуть" : "Развернуть" }}
            </button>
          </td>
          <td></td>
          <td class="toggler-view">
            <Toggler :isOpen="activeTogglerIdx === i">
              <template #body>
                <BonusHistoryDetail :detail="history[i]" />
              </template>
            </Toggler>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BonusHistoryDetail from "@/components/BonusHistoryDetail.vue";
import Coins from "@/components/Coins.vue";
import TaskStatus from "@/components/TaskStatus.vue";
import Toggler from "@/components/Toggler.vue";

/**
 * @typedef TaskHistory
 * @prop {string} month
 * @prop {number} status
 * @prop {{fullName: string, playerReward: number}[]} players
 */

export default {
  components: { TaskStatus, Coins, Toggler, BonusHistoryDetail },
  props: {
    /**
     * @type {import("vue").PropType<TaskHistory[]>}
     */
    history: Array,
  },
  data() {
    return {
      activeTogglerIdx: -1,
    };
  },
  methods: {
    /**
     * @param {number} payload
     */
    toggleView(payload) {
      if (payload === this.activeTogglerIdx) {
        this.activeTogglerIdx = -1;
      } else {
        this.activeTogglerIdx = payload;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.history-bt {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  // .history-bt__title
  &__title {
    margin: 0px 0px 28px 0px;
    font-size: 32px;
    line-height: 37px;
    font-weight: 400;
  }
  // .history-bt__empty
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 223px;
    font-size: 20px;
    line-height: 23.14px;
    color: #ade5d9;
    background: #00fffe0d;
  }
  // .history-bt__table
  &__table {
    width: 100%;
    tr {
      display: grid;
      grid-template-columns: 59px 369px 369px 270px 125px 35px;
    }
  }
  // .history-bt__t-head
  &__t-head {
    tr {
      margin-bottom: 12px;
    }
    th {
      font-size: 16px;
      font-weight: 400;
      line-height: 23.14px;
      text-align: left;
      color: #ade5d9;
    }
  }
  // .history-bt__t-body
  &__t-body {
    tr {
      background: rgba(0, 255, 254, 0.2);
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    td {
      height: 72px;
      padding-top: 21px;
      font-size: 24px;
      font-weight: 700;
      border: none;
    }

    td.toggler-view {
      grid-column: 1 / -1;
      height: auto;
      padding-top: 0;
    }

    button {
      @include arrow;
      @include reset-button;
      position: relative;
      font-size: 18px;
      font-weight: 400;
      line-height: 26.03px;

      &::after {
        margin-left: 10px;
        margin-top: -1px;
      }
      &.active::after {
        transform: rotate(180deg);
        margin-top: -2px;
      }
    }

    .task-status {
      font-size: inherit;
      font-weight: 400;

      &::before {
        margin-bottom: 2px;
      }
    }

    .coins {
      color: #fff;
    }
  }
}
</style>
