<template>
  <div class="t-list" :class="{ _open: isOpen }">
    <div class="t-list__body">
      <div class="t-list__wrap">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.t-list {
  &._open {
    .t-list__body {
      grid-template-rows: 1fr;
    }
  }
  // .t-list__body
  &__body {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s linear, margin-top 0.3s linear;
    margin-top: 0;
  }
  // .t-list__wrap
  &__wrap {
    overflow: hidden;
  }
}
</style>
