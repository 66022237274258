<template>
  <div class="bh-detail">
    <!-- если статус Выполнено, не показываем -->
    <table
      v-if="detail.status < 2"
      class="bh-detail__message-table message-table"
    >
      <thead class="message-table__thead">
        <tr>
          <th>Администратор:</th>
        </tr>
      </thead>
      <tbody class="message-table__tbody">
        <tr>
          <td>
            <p>Ваше задание на проверке у администратора</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="bh-detail__members">
      <table class="bh-detail__members-table members-table">
        <thead class="members-table__thead">
          <tr>
            <th></th>
            <th>Участник команды</th>
            <th>Награда</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="members-table__tbody">
          <tr
            v-for="(d, i) of detail.players.filter((e, i) => !(i % 2))"
            :key="i"
          >
            <td></td>
            <td>{{ d.fullName }}</td>
            <td>
              <Coins :topReward="d.playerReward" :size="13" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table
        v-if="detail.players && detail.players.length"
        class="bh-detail__members-table members-table"
      >
        <thead class="members-table__thead">
          <tr>
            <th></th>
            <th>Участник команды</th>
            <th>Награда</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="members-table__tbody">
          <tr v-for="(d, i) of detail.players.filter((e, i) => i % 2)" :key="i">
            <td></td>
            <td>{{ d.fullName }}</td>
            <td>
              <Coins :topReward="d.playerReward" :size="13" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Coins from "@/components/Coins.vue";

export default {
  components: { Coins },
  props: {
    /**
     * @type {import("vue").PropType<import("@/components/BonusTaskHistory.vue").TaskHistory>}
     */
    detail: Object,
  },
};
</script>

<style lang="scss">
.bh-detail {
  padding: 40px 25px;
  background: #00fffe1a;
  // .bh-detail__message-table
  &__message-table {
    width: 100%;
    margin-bottom: 40px;
  }
  // .bh-detail__members
  &__members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
  // .bh-detail__members-table
  &__members-table {
  }
}

.message-table {
  // .message-table__thead
  &__thead {
    tr {
    }
    th {
      padding-bottom: 10px;
      padding-left: 35px;
      font-size: 18px;
      font-weight: 400;
      color: #ade5d9;
      text-align: left;
    }
  }
  // .message-table__tbody
  &__tbody {
    tr {
      background-color: rgba(0, 255, 254, 0.1);
    }
    td {
    }
    td p {
      min-height: 115px;
      margin: 0;
      padding: 24px 35px;
      font-size: 18px;
      line-height: 26.03px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.members-table {
  // .members-table__thead
  &__thead {
    tr {
      display: grid;
      grid-template-columns: 35px 1fr 65px 30px;
    }

    th {
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23.14px;
      color: #ade5d9;
      text-align: left;
    }
  }
  // .members-table__tbody
  &__tbody {
    tr {
      display: grid;
      grid-template-columns: 35px 1fr 65px 30px;
      width: 100%;
      background-color: rgba(0, 255, 254, 0.35);

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
    td {
      padding-top: 23px;
      min-height: 78px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      border: none;

      & .coins {
        color: inherit;
        justify-content: center;
      }
    }
  }
}
</style>
